<template>
  <div>
    <div class="header text-center">
      <h3 class="title">Medición Calidad</h3>
      <p class="category">
        Bienvenido al Sistema para medición de indicadores de calidad de
        servicio de Red Metropolitana de Movilidad.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  created: function() {
    navigator.geolocation.getCurrentPosition(
      () => {},
      () => {},
      { enableHighAccuracy: true, timeout: 100, maximumAge: 1 * 60 * 1000 }
    );
  },
};
</script>
<style></style>
